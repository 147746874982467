<template>
	<iframe src="https://jingdeng.net/download/" class="iframe_download">

	</iframe>
</template>

<script>
	export default {
		//App.vue
		mounted() {
			if (this._isMobile()) {
				// alert("手机端");
				// this.$router.replace('/mob');
				window.location.href = 'https://jingdeng.net/download/';
			} else {
				// alert("pc端");
				// this.$router.replace('/index.html');
			}
		},
		methods: {
			//App.vue
			_isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
					)
				return flag;
			}
		}
	}
</script>

<style>
	.iframe_download {
		width: 100%;
		height: 1900px;
		margin-bottom: -50px;
		border: 0;
		background-color: white;
	}
</style>
